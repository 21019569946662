import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { EditWorkplace } from './components/edit-workplace/edit-workplace';

export function AddEditWorkplace() {
  return (
    <Routes>
      <Route element={<EditWorkplace />} path="profile/edit/*" />
      <Route element={<EditWorkplace />} path="profile/list/*" />
    </Routes>
  );
}
