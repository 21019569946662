import React, { useEffect } from 'react';
import { FormField, FormLayout, FormSection, Input, TextArea } from '@pata-app/ui-form';
import { useTranslation } from 'react-i18next';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { FormContainer } from '../../../../../components/layouts/app-layout/components/form-container/form-container';
import { type GeneralInfoSection } from '../../../../../types/workplace.types';

interface GeneralInfoProps {
  form: UseFormReturn<GeneralInfoSection>;
}

export function GeneralInfo({ form }: GeneralInfoProps) {
  const { t } = useTranslation('workplaces');

  const {
    register,
    control,
    formState: { errors },
    setFocus,
  } = form;

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);
  return (
    <FormContainer>
      <FormSection title={t('general_info')}>
        <FormLayout columnConfig={{ m: 2 }}>
          <FormField error={errors.name?.message} label={t('business_name')}>
            <Input
              {...register('name', {
                required: 'Workplace Name is required',
                maxLength: { value: 50, message: 'Max character length is 50' },
              })}
            />
          </FormField>
        </FormLayout>
        <FormField error={errors.description?.message} label={t('business_description')}>
          <Controller
            control={control}
            name="description"
            render={({ field }) => {
              return <TextArea {...field} maxCharacterLength={500} />;
            }}
            rules={{
              maxLength: { value: 500, message: 'Max character length is 500' },
              minLength: { value: 50, message: 'Min character length is 50' },
            }}
          />
        </FormField>
      </FormSection>
    </FormContainer>
  );
}
