import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useModalSections(defaultSection: string, isEditMode: boolean) {
  const [searchParams, setSearchParams] = useSearchParams();

  const sectionParam = useMemo(() => searchParams.get('section'), [searchParams]);

  useEffect(() => {
    if (!sectionParam && isEditMode) {
      searchParams.set('section', defaultSection);
      setSearchParams(searchParams);
    }
  }, [sectionParam, searchParams, setSearchParams, defaultSection, isEditMode]);

  const handleChangeSection = useCallback(
    (section: string) => {
      searchParams.set('section', section);
      setSearchParams(searchParams);
    },
    [searchParams],
  );

  return { handleChangeSection, selectedSection: sectionParam ?? '' };
}
