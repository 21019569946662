import styled from 'styled-components';

export const SocialAccountContainer = styled.div`
  display: grid;
  grid-template-columns: 50px 100px auto;
  grid-template-rows: auto;
  gap: ${({ theme }) => theme.core.sizing[1]}px;
  align-items: center;

  width: 100%;
`;

export const SocialAccountName = styled.span`
  font-size: ${({ theme }) => theme.core.fontSizes.sm}px;
  font-weight: ${({ theme }) => theme.core.fontWeight.regular};
`;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;

  svg {
    fill: ${({ theme }) => theme.core.colors.danger.medium};
  }
`;
