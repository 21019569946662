import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

interface ActionsContainerProps {
  $alignment: 'right' | 'left';
  $offset: number;
  $position: 'top' | 'bottom';
}

export const ActionMenuContainer = styled.div`
  position: relative;

  overflow: visible;
`;

export const ActionsContainer = styled(motion.div)<ActionsContainerProps>`
  position: absolute;
  ${({ $position }) => $position}: ${({ $offset }) => $offset}px;

  z-index: 2;

  ${({ $alignment }) => css`
    ${$alignment}: 0;
  `}

  width: 200px;
  height: auto;

  background: ${({ theme }) => theme.core.colors.greyscale.white};
  border: 0.5px solid ${({ theme }) => theme.core.colors.greyscale.medium};

  border-radius: ${({ theme }) => theme.core.border.element.radius / 2}px;

  box-shadow: rgb(0 0 0 / 28%) 0px 4px 10px;

  transition: all 0.3s ease-in-out;
`;

export const ButtonContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.core.sizing[1]}px;

  width: 100%;

  svg {
    fill: ${({ theme }) => theme.core.colors.greyscale.medium};
    transform: rotate(-90deg);
  }
`;
