import React, { useState } from 'react';
import { Icon } from '../icon/icon';
import { CopyButtonContainer } from './copy-button.styled';

interface CopyButtonProps {
  copyText: string;
}

export function CopyButton({ copyText }: CopyButtonProps) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(copyText);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 sec
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <CopyButtonContainer onClick={() => void copyToClipboard()}>
      {copied ? 'Copied!' : <Icon size="16px" type="Copy" />}
    </CopyButtonContainer>
  );
}
