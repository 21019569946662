import React from 'react';
import { ErrorMessageContainer } from './error-message.styled';

interface ErrorMessageProps {
  children: React.ReactNode;
  isFieldError?: boolean;
}

export function ErrorMessage({ children, isFieldError }: ErrorMessageProps) {
  return <ErrorMessageContainer $isFieldError={Boolean(isFieldError)}>{children}</ErrorMessageContainer>;
}
