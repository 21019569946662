import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { navigationConfig } from '../routes.config';
import { Icon } from '../../../../../icon/icon';
import { useMenuPath } from '../hooks/use-menu-path';
import { useGetWorkplaces } from '../../../../../../queries/workplace.queries';
import { SubMenu } from './components/sub-menu/sub-menu';
import {
  MainNavigationContainer,
  SideNavigationContainer,
  SideNavigationItem,
  SubMenuContainer,
  SubMenuToggleButton,
} from './side-navigation.styled';

export function SideNavigation() {
  const { t } = useTranslation('routes');
  const [showSubMenu, setShowSubMenu] = useState(true);

  const { data } = useGetWorkplaces();

  const theme = useTheme();

  const { currentSubMenuConfig, currentPrimaryRoute } = useMenuPath();

  const configWithActionIndicator = navigationConfig.reduce((acc, item) => {
    if (item.path === 'online-booking') {
      return [...acc, { ...item, showIndicator: data?.every((workplace) => workplace.listingStatus === 'unlisted') }];
    }

    return [...acc, item];
  }, []);

  const handleSubMenuToggle = () => {
    setShowSubMenu((currentToggle) => !currentToggle);
  };

  return (
    <SideNavigationContainer $hasSubMenu={Boolean(currentSubMenuConfig)} $showSubMenu={showSubMenu}>
      <MainNavigationContainer>
        {configWithActionIndicator.map((navigationItem) => (
          <>
            <SideNavigationItem
              data-tooltip-id={navigationItem.path}
              data-tooltip-content={t(navigationItem.label)}
              data-tooltip-place="right"
              isSelected={currentPrimaryRoute === navigationItem.path}
              key={navigationItem.path}
              $showActionIndicator={navigationItem.showIndicator}
              to={`/${navigationItem.path}`}
            >
              <Icon size="30px" type={navigationItem.icon} />
            </SideNavigationItem>
            <Tooltip
              style={{
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: theme.core.fontSizes.ml,
                fontWeight: theme.core.fontWeight.medium,
                borderRadius: '8px',
              }}
              id={navigationItem.path}
            />
          </>
        ))}
      </MainNavigationContainer>

      {Boolean(currentSubMenuConfig) && (
        <SubMenuContainer>
          <SubMenuToggleButton onClick={handleSubMenuToggle}>
            <Icon type={showSubMenu ? 'Close' : 'Arrow'} />
          </SubMenuToggleButton>
          <SubMenu />
        </SubMenuContainer>
      )}
    </SideNavigationContainer>
  );
}
