import React from 'react';
import { type Action } from '../../actions-menu';
import { Icon } from '../../../icon/icon';
import { ActionContainer } from './action-item.styled';

interface ActionItemProps {
  action: Action;
  setShowMenu: (show: boolean) => void;
}

export function ActionItem({ action, setShowMenu }: ActionItemProps) {
  function handleClick() {
    setShowMenu(false);

    if (action.onClick) {
      action.onClick(action.id);
    }
  }

  if (action.hide) {
    return;
  }

  return (
    <ActionContainer actionType={action.actionType} disabled={action.disable} key={action.id} onClick={handleClick}>
      {Boolean(action.icon) && <Icon type={action.icon ?? ''} />}
      <span>{action.label}</span>
    </ActionContainer>
  );
}
