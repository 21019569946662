import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigateUsingReferrer } from './use-navigate-using-referrer';

// eslint-disable-next-line -- capture group turned off
const regex = /.+\/(?:edit|list)\/([^\/]+)$/;

export function useAddEditModal(basePath: string, onClosePath?: string, route?: string) {
  const navigate = useNavigateUsingReferrer();
  const location = useLocation();

  const editIdMatch = regex.exec(location.pathname);

  const editId = editIdMatch ? editIdMatch[1] : null;

  const isOpen =
    location.pathname.includes(`${basePath}/edit`) ||
    location.pathname === `${basePath}/add` ||
    location.pathname === `${basePath}/${route}` ||
    location.pathname.includes(`${basePath}/${route}`);

  const closeModal = useCallback(() => {
    const element = document.getElementById('root');

    element?.classList.remove('modal-open');

    navigate(onClosePath || basePath);
  }, [navigate, onClosePath, basePath]);

  return { isOpen, closeModal, editId, isEdit: Boolean(editId) };
}

export type UseAddEditModal = typeof useAddEditModal;
