import { v4 as uuidv4 } from 'uuid';
import { Upload } from '@aws-sdk/lib-storage';
import { type CompleteMultipartUploadCommandOutput, type S3Client } from '@aws-sdk/client-s3';

export interface S3ReturnData {
  data: CompleteMultipartUploadCommandOutput;
  filename: string;
  fileKey: string;
}

const ASSET_BUCKET_NAME = process.env.ASSET_BUCKET_NAME;

export async function uploadFile(
  file: File,
  maxFileSize: number,
  storagePrefix: string,
  onFileUploadComplete: (data: {
    data: CompleteMultipartUploadCommandOutput;
    filename: string;
    fileKey: string;
  }) => void,
  client: S3Client,
  allowedFileTypes?: string[],
) {
  const fileExtension = file.name.split('.').slice(-1)[0].toLowerCase();

  if (allowedFileTypes && allowedFileTypes.length > 0 && !allowedFileTypes.includes(fileExtension.toLowerCase())) {
    throw new Error('Not a supported file type');
  }

  const uploadFileName = `${uuidv4()}.${fileExtension}`;
  const originalFileName = file.name;

  const filePath = storagePrefix;

  const fileKey = filePath + uploadFileName;
  const fileType = file.type;

  const multipartUpload = new Upload({
    client: client,
    params: {
      Bucket: ASSET_BUCKET_NAME,
      Key: fileKey,
      Body: file,
      ACL: 'private',
      ContentDisposition: `attachment; filename="${encodeURIComponent(originalFileName)}"`,
      ContentType: fileType,
    },
  });

  multipartUpload.on('httpUploadProgress', (progress) => {});

  const data = await multipartUpload.done();

  onFileUploadComplete({ data, filename: originalFileName, fileKey });
}
