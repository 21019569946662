import styled from 'styled-components';

export const SocialAccountsLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[0]}px;

  color: ${({ theme }) => theme.core.colors.greyscale.black};

  font-size: ${({ theme }) => theme.core.fontSizes.sm}px;
  font-weight: ${({ theme }) => theme.core.fontWeight.medium};

  > span {
    color: ${({ theme }) => theme.core.colors.danger.medium};

    font-size: ${({ theme }) => theme.core.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.core.fontWeight.regular};
  }
`;
