import styled from 'styled-components';

export const NotificationBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  height: 100%;

  h1 {
    font-size: ${({ theme }) => theme.core.fontSizes.l}px;
  }

  h2 {
    font-size: ${({ theme }) => theme.core.fontSizes.m}px;
    font-weight: ${({ theme }) => theme.core.fontWeight.regular};

    color: ${({ theme }) => theme.core.colors.greyscale.dark};
  }
`;
