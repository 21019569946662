import styled from 'styled-components';

export const PeriodContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.core.sizing[1]}px;
  align-items: center;
  grid-template-columns: minmax(0, 1fr) 20px minmax(0, 1fr) 50px;

  width: 100%;

  > span {
    justify-self: center;
  }
`;

export const PeriodActionButton = styled.button`
  height: 45px;
  width: 45px;

  border: none;

  background: none;

  svg {
    fill: ${({ theme }) => theme.core.colors.greyscale.medium};
  }
`;
