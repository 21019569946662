import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useWatch } from 'react-hook-form';
import { FullScreenModal } from '@pata-app/ui-modal';
import { useTranslation } from 'react-i18next';
import { useAddEditModal } from '../../../../../hooks/use-add-edit-modal';
import { GeneralInfo } from '../forms/general-info';
import { WebAndSocial } from '../forms/web-and-social/web-and-social';
import { useModalSections } from '../../../../../hooks/use-modal-sections';
import { Location } from '../forms/location/location';
import { Media } from '../forms/media/media';
import { useDeleteWorkplace, useGetWorkplace, usePatchWorkplace } from '../../../../../queries/workplace.queries';
import { type Workplace } from '../../../../../types/workplace.types';
import { useAddEditForm } from '../../../../../hooks/use-add-edit-form';
import { formatPatchData } from '../../../../../lib/helpers/format-patch-data';
import { Loader } from '../../../../../components/loader/loader';
import { OpeningTimes } from '../forms/opening-times/opening-times';
import { queryClient } from '../../../../../queries/query-client';
import { addUrlProtocol } from '../../../../../lib/helpers/add-url-protocol';
import { Settings } from '../forms/settings';
import { DEFAULT_FORM_STATE } from './edit-workplace.config';
import { formatMedia } from './edit-workplace.helpers';

export function EditWorkplace() {
  const { t } = useTranslation('workplaces');

  const { pathname } = useLocation();

  const isListScreen = pathname.includes('list');

  const { isOpen, closeModal, editId } = useAddEditModal('/online-booking/profile', undefined, 'list');

  const { data: currentWorkplace, isLoading } = useGetWorkplace(editId);

  const { form, getFormValues } = useAddEditForm<Workplace>({
    defaultValues: DEFAULT_FORM_STATE,
    editData: currentWorkplace,
    closeModal,
    ignoreEmptyFields: true,
  });

  const {
    trigger,
    formState: { isValid },
    setValue,
  } = form;

  useEffect(() => {
    if (isListScreen && currentWorkplace) {
      setValue('listingStatus', 'listed', { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    }
  }, [currentWorkplace]);

  const menuOptions = useMemo(
    () => [
      {
        path: 'general-info',
        label: t('general_info'),
      },
      {
        path: 'opening-times',
        label: t('opening_times'),
      },
      {
        path: 'location',
        label: t('location'),
      },
      {
        path: 'web-and-social',
        label: t('web_and_social'),
      },
      {
        path: 'media',
        label: t('media'),
      },

      {
        path: 'settings',
        label: t('settings'),
      },
    ],
    [t],
  );

  const patchWorkplaceMutation = usePatchWorkplace();
  const deleteWorkplaceMutation = useDeleteWorkplace();

  async function handlePatch() {
    const changedFormValues = await getFormValues();

    if (!changedFormValues) {
      return;
    }

    const formattedMedia = changedFormValues.media && formatMedia(changedFormValues.media, currentWorkplace?.media);

    const currentWorkplaceLocationAddress = {
      line1: currentWorkplace?.locationAddress.line1,
      line2: currentWorkplace?.locationAddress.line2,
      line3: currentWorkplace?.locationAddress.line3,
      city: currentWorkplace?.locationAddress.city,
      country: currentWorkplace?.locationAddress.country,
      region: currentWorkplace?.locationAddress.region,
      postcode: currentWorkplace?.locationAddress.postcode,
    };

    const changedWorkplaceLocationAddress = {
      line1: changedFormValues?.locationAddress?.line1,
      line2: changedFormValues?.locationAddress?.line2,
      line3: changedFormValues?.locationAddress?.line3,
      city: changedFormValues?.locationAddress?.city,
      country: changedFormValues?.locationAddress?.country,
      region: changedFormValues?.locationAddress?.region,
      postcode: changedFormValues?.locationAddress?.postcode,
      latitude: changedFormValues?.locationAddress?.latitude,
      longitude: changedFormValues?.locationAddress?.longitude,
    };

    const currentWorkplaceBillingAddress = {
      line1: currentWorkplace?.billingAddress.line1,
      line2: currentWorkplace?.billingAddress.line2,
      line3: currentWorkplace?.billingAddress.line3,
      city: currentWorkplace?.billingAddress.city,
      country: currentWorkplace?.billingAddress.country,
      region: currentWorkplace?.billingAddress.region,
      postcode: currentWorkplace?.billingAddress.postcode,
    };

    const changedWorkplaceBillingAddress = {
      line1: changedFormValues?.billingAddress?.line1,
      line2: changedFormValues?.billingAddress?.line2,
      line3: changedFormValues?.billingAddress?.line3,
      city: changedFormValues?.billingAddress?.city,
      country: changedFormValues?.billingAddress?.country,
      region: changedFormValues?.billingAddress?.region,
      postcode: changedFormValues?.billingAddress?.postcode,
    };

    if (changedFormValues && editId) {
      patchWorkplaceMutation.mutate(
        {
          id: editId,
          businessDetails: {
            ...changedFormValues,
            media: formattedMedia,
            locationAddress: changedFormValues.locationAddress
              ? formatPatchData(currentWorkplaceLocationAddress, changedWorkplaceLocationAddress)
              : undefined,
            billingAddress: changedFormValues.billingAddress
              ? formatPatchData(currentWorkplaceBillingAddress, changedWorkplaceBillingAddress)
              : undefined,
            website: changedFormValues.website ? addUrlProtocol(changedFormValues.website) : undefined,
          },
        },
        {
          onSuccess: async () => {
            await Promise.all([
              queryClient.invalidateQueries(['workplaces']),
              queryClient.invalidateQueries(['workplace', editId]),
            ]);

            closeModal();
          },
          onError: (error) => console.error(error),
        },
      );
    }
  }

  const { handleChangeSection, selectedSection } = useModalSections('general-info', true);

  function onSectionChange(section: string) {
    if (isValid) {
      handleChangeSection(section);
    } else {
      void trigger();

      toast('Correct form errors before changing section.');
    }
  }

  return (
    <FullScreenModal
      actions={[
        {
          label: t('save', { ns: 'common' }),
          label: t(isListScreen ? 'list' : 'Save', { ns: 'common' }),
          buttonType: 'cta',
          onClick: () => void handlePatch(),
          isLoading: patchWorkplaceMutation.isLoading,
          isDisabled: deleteWorkplaceMutation.isLoading,
        },
      ]}
      handleToggleModal={closeModal}
      isOpen={isOpen}
      modalTitle={`${currentWorkplace?.name} ${t('profile')}`}
    >
      {Boolean(isLoading) && <Loader addPadding={false} />}
      {Boolean(isOpen) && !isLoading && (
        <FullScreenModal.SideMenuContent
          menuOptions={menuOptions}
          onChange={onSectionChange}
          selected={selectedSection}
        >
          {
            {
              'general-info': <GeneralInfo form={form} />,
              'web-and-social': <WebAndSocial form={form} />,
              'opening-times': <OpeningTimes form={form} />,
              location: <Location form={form} />,
              media: <Media form={form} />,
              settings: (
                <Settings form={form} isEdit slug={currentWorkplace?.slug} workplaceId={currentWorkplace?.id} />
              ),
            }[selectedSection]
          }
        </FullScreenModal.SideMenuContent>
      )}
    </FullScreenModal>
  );
}
