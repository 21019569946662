import React from 'react';
import { WeekTimesInputContainer } from './week-times-input.styled';
import { TimeDay } from './components/week-time-day/week-time-day';

export interface DayTime {
  startTime: number;
  endTime: number;
}

export interface DayValue {
  day: number;
  periods: DayTime[];
}

interface WeekTimesInputProps {
  value: DayValue[];
  onChange: (value: DayValue[]) => void;
  noPeriodsLabel?: string;
  allowMultiPeriods: boolean;
  maxNumberOfPeriods?: number;
  keyword: string;
}

export const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export function WeekTimesInput({
  value,
  onChange,
  noPeriodsLabel,
  allowMultiPeriods,
  maxNumberOfPeriods,
  keyword = 'period',
}: WeekTimesInputProps) {
  function handleChange(updatedDay: DayValue) {
    if (updatedDay.periods) {
      const updatedWeekValue = days.reduce((updatedValue, _, index) => {
        const hasUpdatedDay = updatedDay.day === index;

        if (hasUpdatedDay) {
          return [...updatedValue, updatedDay];
        }

        const hasExistingDay = value.map((dayValue) => dayValue.day).includes(index);

        if (hasExistingDay) {
          return [...updatedValue, value.find((day) => day.day === index)];
        }

        return updatedValue;
      }, []);

      onChange(updatedWeekValue);
    } else {
      const updatedWeekValue = value.filter((day) => day.day !== updatedDay.day);

      onChange(updatedWeekValue);
    }
  }

  return (
    <WeekTimesInputContainer onClick={(event) => event.preventDefault()}>
      {days.map((day, index) => (
        <TimeDay
          allowMultiPeriods={allowMultiPeriods}
          day={day}
          index={index}
          key={day}
          keyword={keyword}
          maxNumberOfPeriods={maxNumberOfPeriods}
          noPeriodsLabel={noPeriodsLabel}
          onChange={handleChange}
          value={value.find((valueDay) => valueDay.day === index)}
        />
      ))}
    </WeekTimesInputContainer>
  );
}
