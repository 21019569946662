import React from 'react';
import { FormField, FormLayout, FormSection, Input } from '@pata-app/ui-form';
import { useTranslation } from 'react-i18next';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { FormContainer } from '../../../../../../components/layouts/app-layout/components/form-container/form-container';
import { type WebAndSocialSection } from '../../../../../../types/workplace.types';
import { SocialAccountInput } from './components/social-account-input/social-account-input';
import { SocialAccountsLabel } from './web-and-social.styled';

interface WebAndSocialProps {
  form: UseFormReturn<WebAndSocialSection>;
}

export function WebAndSocial({ form }: WebAndSocialProps) {
  const { t } = useTranslation('workplaces');

  const {
    control,
    register,
    formState: { errors },
  } = form;

  return (
    <FormContainer>
      <FormSection description={t('web_and_social_description')} title={t('web_and_social')}>
        <FormLayout columnConfig={{ m: 2 }}>
          <FormField error={errors.website?.message} label={t('website')}>
            <Input
              {...register('website', {
                maxLength: { value: 254, message: 'Max character length is 254' },
                pattern: {
                  message: 'A valid URL must be provided',
                  value:
                    /(https:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                },
                setValueAs: (value: string) => (value ? value.trim() : null),
              })}
              placeholder="https://..."
              type="url"
            />
          </FormField>
        </FormLayout>

        <SocialAccountsLabel>
          {t('social_accounts')}
          <span>{errors.socialMediaProfiles?.message}</span>
          <Controller
            control={control}
            name="socialMediaProfiles"
            render={({ field }) => {
              return <SocialAccountInput {...field} />;
            }}
            rules={{
              validate: (value) => {
                if (
                  value?.some(
                    (account) =>
                      !account.url.match(
                        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                      ),
                  )
                ) {
                  return 'A Valid URL must be provided for each account';
                }

                if (value?.some((account) => account.url.length > 254)) {
                  return 'Max URL character length is 254';
                }
              },
            }}
          />
        </SocialAccountsLabel>
      </FormSection>
    </FormContainer>
  );
}
