import { EditorContent } from '@tiptap/react';
import styled from 'styled-components';

interface ActionButtonProps {
  $isActive?: boolean;
}

const TEXT_EDITOR_MIN_HEIGHT = 200;

export const TextEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[2]}px;
`;

export const TextEditorActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.core.sizing[1]}px;
`;

export const EditContentContainer = styled(EditorContent)`
  border-radius: ${({ theme }) => theme.core.border.element.radius}px;

  border: 0.5px solid ${({ theme }) => theme.core.colors.greyscale.medium};

  > div {
    min-height: ${TEXT_EDITOR_MIN_HEIGHT}px;

    padding: ${({ theme }) => theme.core.sizing[2]}px;
  }
`;

export const ActionsGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.core.sizing[0]}px;

  &:not(:last-of-type) {
    padding-right: ${({ theme }) => theme.core.sizing[0]}px;

    border-right: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};
  }

  &:not(:first-of-type) {
    padding-left: ${({ theme }) => theme.core.sizing[0]}px;
  }
`;

export const ActionButton = styled.button<ActionButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 25px;
  width: 25px;

  border: none;
  border-radius: ${({ theme }) => theme.core.border.element.radius}px;

  background: ${({ $isActive, theme }) =>
    $isActive ? theme.core.colors.primary.green.selected : theme.core.colors.greyscale.white};

  color: ${({ $isActive, theme }) =>
    $isActive ? theme.core.colors.primary.green.medium : theme.core.colors.greyscale.medium};

  svg {
    fill: ${({ $isActive, theme }) =>
      $isActive ? theme.core.colors.primary.green.medium : theme.core.colors.greyscale.medium};
  }

  &:hover {
    background: ${({ theme }) => theme.core.colors.primary.green.hover};
  }
`;
