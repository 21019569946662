import { useCallback, useEffect } from 'react';
import { type InvalidateQueryFilters } from 'react-query';
import { type DefaultValues, useForm, type FieldValues } from 'react-hook-form';
import { queryClient } from '../queries/query-client';

interface UseAddEditClosePeriodFormProps<T> {
  defaultValues: T;
  editData: T | undefined;
  resourceCache?: InvalidateQueryFilters;
  closeModal: () => void;
  ignoreEmptyFields?: boolean;
}

export function useAddEditForm<T extends DefaultValues<FieldValues>>({
  defaultValues,
  editData,
  resourceCache,
  closeModal,
  ignoreEmptyFields,
}: UseAddEditClosePeriodFormProps<T>) {
  const form = useForm<T>({
    defaultValues,
    mode: 'onBlur',
  });

  const {
    reset,
    trigger,
    formState: { isDirty, isValid, dirtyFields },
    getValues,
  } = form;

  async function onSuccess() {
    if (resourceCache) {
      await queryClient.invalidateQueries(resourceCache);
    }

    closeModal();
  }

  useEffect(() => {
    if (editData) {
      reset(editData);
    } else {
      reset(defaultValues);
    }
  }, [editData, reset, defaultValues]);

  const getFormValues = useCallback(async () => {
    await trigger();

    if (!isDirty && !ignoreEmptyFields) {
      closeModal();
    }

    if (isValid) {
      const formValues = getValues();

      const changedFieldNames = Object.keys(dirtyFields).reduce((changedfields, fieldName) => {
        if (dirtyFields[fieldName]) {
          return [...changedfields, fieldName];
        }
        return changedfields;
      }, []);

      const changedFormValues: Partial<T> = Object.keys(formValues).reduce((changedFields, field) => {
        if (changedFieldNames.includes(field)) {
          return {
            ...changedFields,
            [field]: formValues[field as keyof typeof formValues],
          };
        }
        return changedFields;
      }, {});

      return changedFormValues;
    }
  }, [dirtyFields, isDirty, isValid, trigger, getValues]);

  const onFormSubmit = useCallback(async () => {
    await trigger();

    if (!isDirty) {
      closeModal();

      return;
    }

    if (isValid) {
      return getValues();
    }

    return false;
  }, [getValues, isDirty, trigger, isValid, closeModal]);

  return { form, getFormValues, onSuccess, onFormSubmit };
}
