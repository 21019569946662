import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormSection, Button } from '@pata-app/ui-form';
import { type UseFormReturn } from 'react-hook-form';
import { FormContainer } from '../../../../../../components/layouts/app-layout/components/form-container/form-container';
import { ErrorMessage } from '../../../../../../components/error-message/error-message';
import { type MediaSection, type Media } from '../../../../../../types/workplace.types';
import { ImageUpload } from '../../../../../../components/image-upload/image-upload';
import { EmptyState } from '../../../../../../components/empty-state/empty-state';
import { AddButtonContainer, MainMediaContainer, OtherMediaContainer, UploadPlaceholderButton } from './media.styled';
import { MediaTile } from './components/media-tile';

interface MediaProps {
  form: UseFormReturn<MediaSection>;
}

export function Media({ form }: MediaProps) {
  const {
    watch,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  const { t } = useTranslation('workplaces');

  const media = watch('media');

  function handleMediaUpload(filePath: string, fileName: string) {
    const currentMedia = getValues('media');

    setValue('media', [...currentMedia, { path: filePath, name: fileName }], {
      shouldValidate: true,
      shouldDirty: true,
    });
  }

  function removeMedia(path: string) {
    const currentMedia = getValues('media');

    const updatedMedia = currentMedia.filter((image) => image.path !== path);

    setValue('media', updatedMedia, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }

  const mainMedia = media?.length > 0 && media[0];

  const otherMedia = media?.length > 1 ? media.slice(1) : [];

  return (
    <FormContainer>
      <FormSection description={t('media_description')} title={t('media')}>
        <ErrorMessage>{errors.media?.message}</ErrorMessage>
        <ImageUpload
          {...register('media', { required: 'At least one image is required' })}
          aspectRatio={16 / 9}
          hasImage={false}
          onChange={handleMediaUpload}
          storagePrefix="uploads/"
        >
          <AddButtonContainer>
            <UploadPlaceholderButton>Upload Media</UploadPlaceholderButton>
          </AddButtonContainer>
        </ImageUpload>
        <ImageUpload
          {...register('media')}
          aspectRatio={16 / 9}
          hasImage={false}
          onChange={handleMediaUpload}
          storagePrefix="uploads/"
        >
          {!mainMedia && (
            <EmptyState
              description="Click to add media to your workplace profile"
              icon="Media"
              title="No Media added yet"
            />
          )}
        </ImageUpload>

        {Boolean(mainMedia) && (
          <MainMediaContainer>
            <span>{t('cover_image')}</span>
            <MediaTile handleDelete={removeMedia} path={mainMedia ? mainMedia.path : ''} />
          </MainMediaContainer>
        )}

        {Boolean(otherMedia) && (
          <OtherMediaContainer>
            {otherMedia?.map((image: Media) => (
              <MediaTile handleDelete={removeMedia} key={image.path} path={image.path} />
            ))}
          </OtherMediaContainer>
        )}
      </FormSection>
    </FormContainer>
  );
}
