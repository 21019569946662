import { useTranslation } from 'react-i18next';
import { Modal } from '@pata-app/ui-modal';
import { toast } from 'react-toastify';
import React, { useRef, useState } from 'react';
import Cropper, { type ReactCropperElement } from 'react-cropper';
import { Icon } from '../icon/icon';
import { type S3ReturnData } from '../../lib/upload/s3-uploads';
import { useS3FileUpload } from './hooks/use-file-upload';
import { DeleteButton, FileUploadContainer } from './image-upload.styled';
import { dataURLtoFile } from './helpers/data-url-to-file';
import 'cropperjs/dist/cropper.css';

export interface ImageUploadProps {
  onChange: (file: string | null, fileName?: string) => void;
  allowMulti?: boolean;
  children: React.ReactNode;
  imageName?: string;
  hasImage: boolean;
  storagePrefix: string;
  aspectRatio?: number;
}

export function ImageUpload({
  onChange,
  allowMulti = false,
  children,
  imageName,
  hasImage,
  storagePrefix,
  aspectRatio = 1,
  maxFileSize = 5000000,
}: ImageUploadProps) {
  const [tempFileBlob, setTempFileBlob] = useState<null | string>(null);

  const [uploadingFile, setUploadingFile] = useState(false);

  const [file, setFile] = useState<null | File>(null);

  const { t } = useTranslation('common');

  const cropperRef = useRef<ReactCropperElement>(null);

  function handleCloseModal() {
    setTempFileBlob(null);
  }

  function handleMediaUploadComplete(data: S3ReturnData) {
    setTempFileBlob(null);
    setUploadingFile(false);

    onChange(data.fileKey, data.filename);
  }

  function handleMediaUploadError(error: Error) {
    setUploadingFile(false);
    toast(error.message);
  }

  const { onFileUpload } = useS3FileUpload(
    handleMediaUploadComplete,
    handleMediaUploadError,
    maxFileSize,
    ['jpg', 'jpeg', 'png', 'webp'],
    storagePrefix,
  );

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const fileSize = event.target.files[0].size;

      if (fileSize > maxFileSize) {
        toast(`File too large, max size is ${maxFileSize / 1000000}mb`);

        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setTempFileBlob(reader.result as string);
      };

      setFile(event.target.files[0]);
    }
  };

  function handleDelete(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    onChange(null);
  }

  function handleUploadImage() {
    const cropper = cropperRef.current?.cropper;

    const croppedData = cropper?.getCroppedCanvas().toDataURL();

    if (croppedData) {
      setUploadingFile(true);

      const uploadFile = dataURLtoFile(croppedData, file?.name ?? 'image.png');
      void onFileUpload(uploadFile);
    }
  }

  return (
    <>
      <FileUploadContainer style={{ border: 'none', width: '100%', position: 'relative', overflow: 'hidden' }}>
        <label>
          <input accept=".jpg,.jpeg,.png,.webp" multiple={allowMulti} onChange={handleUpload} type="file" />
          {children}
        </label>

        {Boolean(hasImage) && (
          <DeleteButton onClick={handleDelete}>
            <Icon type="Close" />
          </DeleteButton>
        )}
      </FileUploadContainer>
      <Modal
        actions={[
          {
            label: t('cancel'),
            onClick: handleCloseModal,
            buttonType: 'secondary',
          },
          {
            label: t('upload'),
            onClick: handleUploadImage,
            isLoading: uploadingFile,
          },
        ]}
        handleToggleModal={setTempFileBlob}
        isOpen={Boolean(tempFileBlob)}
        modalTitle={`Upload ${imageName ?? 'Media'}`}
      >
        <div>
          <Cropper
            aspectRatio={aspectRatio}
            background={false}
            checkOrientation={false}
            guides
            initialAspectRatio={1}
            minCropBoxHeight={100}
            minCropBoxWidth={100}
            preview=".img-preview"
            ref={cropperRef}
            responsive
            src={tempFileBlob ?? undefined}
            style={{ height: 300, width: '100%' }}
            viewMode={1}
          />
        </div>
      </Modal>
    </>
  );
}
