import styled from 'styled-components';

export const MediaTileContainer = styled.div`
  position: relative;

  img {
    width: 100%;

    border-radius: ${({ theme }) => theme.core.border.element.radius}px;

    -webkit-filter: brightness(100%);

    transition: all 0.2s ease-in-out;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: ${({ theme }) => theme.core.sizing[1] + 8}px;
    right: ${({ theme }) => theme.core.sizing[1]}px;

    width: 45px;
    height: 45px;

    opacity: 0;

    background: none;

    border-radius: ${({ theme }) => theme.core.border.container.radius}px;
    border: none;

    transition: all 0.2s ease-in-out;
  }

  &:hover {
    img {
      -webkit-filter: brightness(70%);
    }

    button {
      opacity: 1;
      background: ${({ theme }) => theme.core.colors.greyscale.white};

      svg {
        fill: ${({ theme }) => theme.core.colors.greyscale.black};
      }
    }
  }
`;
