import React, { useMemo, useState } from 'react';
import { Button, selectStyles } from '@pata-app/ui-form';
import Select from 'react-select';
import { type SocialMediaProfile } from '../../../../../../../../types/workplace.types';
import { SocialAccount } from './components/social-account';
import {
  AddSocialAccountContainer,
  NoSocialAccountsMessage,
  SocialAccountInputContainer,
} from './social-account-input.styled';
import { SOCIAL_ACCOUNTS } from './social-account-input.config';

interface SocialAccountInputProps {
  value: SocialMediaProfile[] | undefined;
  onChange: (value: SocialMediaProfile[]) => void;
}

export function SocialAccountInput({ value, onChange, onBlur }: SocialAccountInputProps) {
  const [newSocialAccount, setNewSocialAccount] = useState<null | { id: string; label: string }>(null);

  function handleChange(name: string, url: string) {
    const updatedSocialAccounts = value?.reduce((newValue, socialAccount) => {
      if (socialAccount.name === name) {
        return [
          ...newValue,
          {
            name,
            url,
          },
        ];
      }

      return [...newValue, socialAccount];
    }, []);

    if (updatedSocialAccounts) {
      onChange(updatedSocialAccounts);
    }
  }

  function handleDelete(socialAccountName: string) {
    const updatedSocialAccounts = value?.filter((socialAccount) => socialAccount.name !== socialAccountName);

    if (updatedSocialAccounts) {
      onChange(updatedSocialAccounts);
    }
  }

  function handleSelectNewSocialAccount(event: unknown) {
    setNewSocialAccount(event);
  }

  function handleAddSocialAccount() {
    if (newSocialAccount) {
      const updatedSocialAccounts = [
        ...((value as SocialMediaProfile[]) ?? []),
        { name: newSocialAccount.id, url: '' },
      ];

      onChange(updatedSocialAccounts);
      setNewSocialAccount(null);
    }
  }

  const currentScoialAccounts = useMemo(() => value?.map((socialAccount) => socialAccount.name), [value]);

  const newSocialAccountsList = useMemo(
    () => SOCIAL_ACCOUNTS.filter((account) => !currentScoialAccounts?.includes(account.id)),
    [currentScoialAccounts],
  );

  return (
    <SocialAccountInputContainer>
      {Boolean(value) && value?.length ? (
        value.map((socialAccount) => (
          <SocialAccount
            key={socialAccount.name}
            onBlur={onBlur}
            onChange={handleChange}
            onDelete={handleDelete}
            socialName={socialAccount.name}
            socialUrl={socialAccount.url}
          />
        ))
      ) : (
        <NoSocialAccountsMessage>No social accounts added</NoSocialAccountsMessage>
      )}

      <AddSocialAccountContainer>
        <Select
          onChange={handleSelectNewSocialAccount}
          options={newSocialAccountsList}
          placeholder="Select to add new social account"
          styles={selectStyles}
          value={newSocialAccount}
        />
        {Boolean(newSocialAccount) && <Button onClick={handleAddSocialAccount}>Add Social</Button>}
      </AddSocialAccountContainer>
    </SocialAccountInputContainer>
  );
}
