import styled from 'styled-components';

interface NewUploadContainerProps {
  $mediaAdded: boolean;
}

export const NewUploadContainer = styled.div<NewUploadContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  height: ${({ $mediaAdded }) => ($mediaAdded ? 100 : 500)}px;
  width: 100%;

  border-radius: ${({ theme }) => theme.core.border.container.radius}px;
  border: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};

  color: ${({ theme }) => theme.core.colors.primary.green.medium};

  font-weight: ${({ theme }) => theme.core.fontWeight.medium};

  svg {
    fill: ${({ theme }) => theme.core.colors.primary.green.medium};
  }
`;

export const AddButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 100%;
`;

export const MainMediaContainer = styled.div`
  position: relative;

  span {
    position: absolute;
    right: 0;
    top: 0;

    padding: ${({ theme }) => theme.core.sizing[0]}px;

    border-radius: 0 ${({ theme }) => theme.core.border.element.radius}px 0
      ${({ theme }) => theme.core.border.element.radius}px;

    background: ${({ theme }) => theme.core.colors.primary.green.medium};
    color: ${({ theme }) => theme.core.colors.greyscale.white};

    z-index: 1;
  }
`;

export const OtherMediaContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: ${({ theme }) => theme.core.sizing[2]}px;
`;

export const UploadPlaceholderButton = styled.div`
  display: flex;
  align-items: center;

  height: 45px;
  padding: ${({ theme }) => theme.core.sizing[3]}px ${({ theme }) => theme.core.sizing[2]}px;

  font-size: ${({ theme }) => theme.core.fontSizes.ml}px;
  font-weight: ${({ theme }) => theme.core.fontWeight.medium};

  border: 1px solid ${({ theme }) => theme.core.colors.greyscale.black};
  border-radius: ${({ theme }) => theme.core.border.element.radius}px;

  color: ${({ theme }) => theme.core.colors.greyscale.white};

  background: ${({ theme }) => theme.core.colors.greyscale.black};

  border: 1px solid ${({ theme }) => theme.core.colors.greyscale.black};

  &:hover {
    background: ${({ theme }) => theme.core.colors.greyscale.black}1A;
  }

  &:focus {
    box-shadow: var(
      --focus-ring,
      0 0 0 2px ${({ theme }) => theme.core.colors.greyscale.white},
      0 0 0 4px ${({ theme }) => theme.core.colors.primary.green.medium}
    );
  }
`;
