import styled from 'styled-components';

export const IconPlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  aspect-ratio: 1.6 / 1;

  border-radius: ${({ theme }) => theme.core.border.element.radius}px;

  background: ${({ theme }) => theme.core.colors.primary.green.light};

  svg {
    fill: ${({ theme }) => theme.core.colors.primary.green.dark};
  }
`;
