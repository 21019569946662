// export function formatPatchData<T extends Record<string, any>>(original: T, updated: T) {
//   const differences: Partial<T> = {};

//   for (const key in updated) {
//     if (!original.hasOwnProperty(key)) {
//       differences[key] = updated[key];
//     } else if (typeof updated[key] !== 'object' || updated[key] === null) {
//       if (original[key] !== updated[key]) {
//         differences[key] = updated[key];
//       }
//     } else if (Array.isArray(updated[key])) {
//       const originalArray = original[key] || [];
//       const updatedArray = updated[key];

//       const originalMap = new Map(originalArray.map((item: AnyObject) => [item.id, item]));
//       const updatedMap = new Map(updatedArray.map((item: AnyObject) => [item.id, item]));

//       const arrayDifferences = updatedArray
//         .map((item: AnyObject) => {
//           const originalItem = originalMap.get(item.id);

//           if (!originalItem) {
//             const createdItem = Object.keys(item).reduce((acc: AnyObject, k: string) => {
//               if (typeof item[k] === 'object' && item[k] !== null && 'id' in item[k]) {
//                 acc[k] = { id: item[k].id };
//               } else {
//                 acc[k] = item[k];
//               }
//               return acc;
//             }, {});
//             return { ...createdItem, action: 'add' };
//           }

//           const diff = formatPatchData(originalItem, item);

//           if (Object.keys(diff).length > 0) {
//             return { ...diff, id: item.id, action: 'update' };
//           }
//           return null;
//         })
//         .filter((diff: any) => diff !== null);

//       const removedItems = originalArray
//         .filter((item: AnyObject) => !updatedMap.has(item.id))
//         .map((item: AnyObject) => ({ id: item.id, action: 'remove' }));

//       if (arrayDifferences.length > 0 || removedItems.length > 0) {
//         differences[key] = [...arrayDifferences, ...removedItems];
//       }
//     } else {
//       const nestedDifferences = formatPatchData(original[key], updated[key]);

//       if (Object.keys(nestedDifferences).length > 0) {
//         if (original[key]?.id !== updated[key]?.id) {
//           differences[key] = { id: updated[key].id, action: 'associate' };
//         } else {
//           differences[key] = nestedDifferences;
//         }
//       }
//     }
//   }

//   for (const key in original) {
//     if (!updated.hasOwnProperty(key)) {
//       differences[key] = { id: original[key]?.id, action: 'remove' };
//     }
//   }

//   return differences;
// }

export function formatPatchData<T extends Record<string, any>>(original: T, updated: T) {
  const differences: Partial<T> = {};

  for (const key in updated) {
    if (!original?.hasOwnProperty(key)) {
      differences[key] = updated[key];
    } else if (typeof updated[key] !== 'object' || updated[key] === null) {
      if (original[key] !== updated[key]) {
        differences[key] = updated[key];
      }
    } else if (Array.isArray(updated[key])) {
      const originalArray = original[key] || [];
      const updatedArray = updated[key];

      // Check if the items in the array have 'id'. If not, directly assign the updated array
      if (
        (updatedArray.length > 0 && typeof updatedArray[0] !== 'object') ||
        !(updatedArray[0] && 'id' in updatedArray[0])
      ) {
        if (JSON.stringify(originalArray) !== JSON.stringify(updatedArray)) {
          differences[key] = updatedArray;
        }
        continue; // Skip the rest of the array processing since there's no 'id'
      }

      // Map for arrays that contain objects with 'id'
      const originalMap = new Map(originalArray.map((item: any) => [item.id, item]));
      const updatedMap = new Map(updatedArray.map((item: any) => [item.id, item]));

      const arrayDifferences = updatedArray
        .map((item: any) => {
          const originalItem = originalMap.get(item.id);

          if (!originalItem) {
            const createdItem = Object.keys(item).reduce((acc: any, k: string) => {
              if (typeof item[k] === 'object' && item[k] !== null && 'id' in item[k]) {
                acc[k] = { id: item[k].id };
              } else {
                acc[k] = item[k];
              }
              return acc;
            }, {});
            
            return { ...createdItem, action: 'add' };
          }

          const diff = formatPatchData(originalItem, item);

          if (Object.keys(diff).length > 0) {
            return { ...diff, id: item.id, action: 'update' };
          }
          return null;
        })
        .filter((diff: any) => diff !== null);

      const removedItems = originalArray
        .filter((item: any) => !updatedMap.has(item.id))
        .map((item: any) => ({ id: item.id, action: 'remove' }));

      if (arrayDifferences.length > 0 || removedItems.length > 0) {
        differences[key] = [...arrayDifferences, ...removedItems];
      }
    } else {
      const nestedDifferences = formatPatchData(original[key], updated[key]);

      if (Object.keys(nestedDifferences).length > 0) {
        if (original[key]?.id !== updated[key]?.id) {
          differences[key] = { id: updated[key].id, action: 'associate' };
        } else {
          differences[key] = nestedDifferences;
        }
      }
    }
  }

  for (const key in original) {
    if (!updated?.hasOwnProperty(key)) {
      differences[key] = { id: original[key]?.id, action: 'remove' };
    }
  }

  return differences;
}
