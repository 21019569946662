export const DEFAULT_FORM_STATE = {
  name: '',
  description: '',
  website: '',
  socialMediaProfiles: [],
  openingTimes: [],
  media: [],
  locationAddress: {
    line1: '',
    line2: '',
    line3: '',
    city: '',
    postcode: '',
    region: '',
    country: '',
  },
  hideAddress: false,
  id: '',
  acceptNewClients: true,
  termsAndConditions: '',
};
