import styled from 'styled-components';
import { IconPlaceholderContainer } from '../../../../components/icon-placeholder/icon-placeholder.styled';

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;

  height: 200px;
  overflow: hidden;

  ${IconPlaceholderContainer} {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;

    border-radius: ${({ theme }) => theme.core.border.element.radius / 2}px
      ${({ theme }) => theme.core.border.element.radius / 2}px;
  }

  img {
    position: relative;

    width: 100%;
    height: 100%;

    object-fit: cover;

    z-index: 1;

    border-radius: 4px 4px 0 0;
  }

  svg {
    fill: ${({ theme }) => theme.core.colors.greyscale.white};
  }
`;

export const WorkplaceTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  border: 0.5px solid ${({ theme }) => theme.core.colors.greyscale.medium};
  border-radius: 4px;

  color: ${({ theme }) => theme.core.colors.greyscale.black};

  padding: ${({ theme }) => theme.core.sizing[2]}px;

  ${ImageContainer} {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: grid;
    grid-template-columns: minmax(350px, 30%) auto min-content;
    grid-template-rows: auto min-content;

    ${ImageContainer} {
      grid-row: 1 / span 2;

      align-self: center;
      justify-self: center;
    }
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WorkplaceName = styled.h2`
  grid-row: 1 / span 1;
  grid-column: 2 / span 1;

  font-size: ${({ theme }) => theme.core.fontSizes.l}px;

  word-break: break-all;
  text-overflow: ellipsis;
`;

export const WorkplaceAddress = styled.p`
  grid-row: 2 / span 1;
  grid-column: 2 / span 1;

  color: ${({ theme }) => theme.core.colors.greyscale.dark};
  font-size: ${({ theme }) => theme.core.fontSizes.sm}px;
`;

export const WorkplaceCategory = styled.div`
  color: ${({ theme }) => theme.core.colors.primary.green.medium};

  text-transform: capitalize;
  font-size: ${({ theme }) => theme.core.fontSizes.m}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 25px;
  }
`;

export const ListedIndicator = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  padding: 0 ${({ theme }) => theme.core.sizing[2]}px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;

  font-size: ${({ theme }) => theme.core.fontSizes.sm}px;

  color: ${({ theme }) => theme.core.colors.greyscale.white};
  background: ${({ theme }) => theme.core.colors.primary.green.medium};

  z-index: 2;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.core.sizing[2]}px;
  align-self: flex-end;
  grid-row: 2 / span 1;
  grid-column: 3 / span 1;

  button {
    height: 36px;
    padding: 0 8px;

    min-width: 120px;
  }
`;

export const ActionMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  gap: ${({ theme }) => theme.core.sizing[0]}px;

  border: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};
  background: none;

  font-weight: 500;

  border-radius: ${({ theme }) => theme.core.border.element.radius}px;

  transition: all 0.2s ease-in-out;

  svg {
    transform: rotate(270deg);
    fill: ${({ theme }) => theme.core.colors.greyscale.dark};
  }
`;

export const ProfileLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[1]}px;

  grid-column: 2 / span 1;
  grid-row: 2 / span 1;

  > div {
    display: flex;
    gap: ${({ theme }) => theme.core.sizing[2]}px;
  }

  span {
    display: flex;
    align-items: center;
    height: 16px;
    gap: ${({ theme }) => theme.core.sizing[1]}px;

    a {
      color: ${({ theme }) => theme.core.colors.primary.blue.medium};
      font-size: ${({ theme }) => theme.core.fontSizes.m}px;
    }
    svg {
      fill: ${({ theme }) => theme.core.colors.primary.blue.medium};
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    align-self: flex-end;
  }
`;

export const UnlistedMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  background-color: #00000066;
  z-index: 2;

  border-radius: 4px;

  span {
    color: ${({ theme }) => theme.core.colors.greyscale.white};

    font-size: ${({ theme }) => theme.core.fontSizes.l}px;
    font-weight: ${({ theme }) => theme.core.fontWeight.medium};
  }
`;
