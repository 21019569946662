import { useCallback, useState } from 'react';
import { S3Client, type S3ClientConfigType } from '@aws-sdk/client-s3';
import { fetchAuthSession } from 'aws-amplify/auth';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { type S3ReturnData, uploadFile } from '../../../lib/upload/s3-uploads';

export function useS3FileUpload(
  onFileUploadComplete: (data: S3ReturnData) => void,
  onFileUploadError: (error: unknown) => void,
  maxFileSize = 5000000,
  allowedFileTypes?: string[],
  storagePrefix,
) {
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onFileUpload = useCallback(
    async (file: File) => {
      try {
        if (error) {
          setError(null);
        }

        const user = await fetchAuthSession();

        const idToken = user.tokens?.idToken?.toString();

        const loginData = {
          [`cognito-idp.eu-west-1.amazonaws.com/${process.env.AWS_COGNITO_USER_POOL_ID}`]: idToken,
        };

        const client: S3ClientConfigType = new S3Client({
          region: process.env.AWS_COGNITO_REGION,
          credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: process.env.AWS_COGNITO_REGION }),
            logins: loginData,
            identityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
          }),
          useAccelerateEndpoint: true,
        });

        setUploadInProgress(true);

        await Promise.all(
          Array.from([file]).map((fileToUpload) => {
            return uploadFile(fileToUpload, maxFileSize, storagePrefix, onFileUploadComplete, client, allowedFileTypes);
          }),
        );

        setUploadInProgress(false);
      } catch (uploadError: unknown) {
        console.error(uploadError);

        setError(uploadError.message as string);

        onFileUploadError(uploadError);
      }
    },
    [error],
  );

  return { onFileUpload, uploadInProgress, error };
}
