import React, { useEffect } from 'react';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import CharacterCount from '@tiptap/extension-character-count';
import { Icon } from '../icon/icon';
import {
  ActionButton,
  ActionsGroup,
  EditContentContainer,
  TextEditorActionsContainer,
  TextEditorContainer,
} from './text-editor.styled';

interface TextEditorProps {
  value: string;
  onChange: (text: string | null) => void;
  onBlur: (text: string | null) => void;
  removeOptions?: string[];
}

export function TextEditor({ onChange, value, removeOptions, onBlur }: TextEditorProps) {
  const editor = useEditor({
    extensions: [StarterKit, Bold, Italic, Underline, ListItem, OrderedList, CharacterCount],
    content: value,

    onUpdate: ({ editor }) => {
      const html = editor.getHTML();

      const characterCount = editor.storage.characterCount.characters();

      onChange(characterCount ? html : null);
    },
    onBlur: ({ editor }) => {
      const html = editor.getHTML();

      const characterCount = editor.storage.characterCount.characters();

      onBlur(characterCount ? html : null);
    },
  });

  useEffect(() => {
    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, [editor]);

  return (
    <TextEditorContainer>
      <TextEditorActionsContainer>
        <ActionsGroup>
          {!removeOptions?.includes('bold') && (
            <ActionButton
              $isActive={editor?.isActive('bold')}
              disabled={!editor?.can().chain().focus().toggleBold().run()}
              onClick={() => editor?.chain().focus().toggleBold().run()}
              title="Bold"
            >
              <Icon type="Bold" />
            </ActionButton>
          )}

          {!removeOptions?.includes('italic') && (
            <ActionButton
              $isActive={editor?.isActive('italic')}
              disabled={!editor?.can().chain().focus().toggleItalic().run()}
              onClick={() => editor?.chain().focus().toggleItalic().run()}
              title="Italic"
            >
              <Icon type="Italic" />
            </ActionButton>
          )}

          {!removeOptions?.includes('underline') && (
            <ActionButton
              $isActive={editor?.isActive('underline')}
              disabled={!editor?.can().chain().focus().toggleUnderline().run()}
              onClick={() => editor?.chain().focus().toggleUnderline().run()}
              title="Underline"
            >
              <Icon type="Underline" />
            </ActionButton>
          )}

          {!removeOptions?.includes('strike') && (
            <ActionButton
              $isActive={editor?.isActive('strike')}
              disabled={!editor?.can().chain().focus().toggleStrike().run()}
              onClick={() => editor?.chain().focus().toggleStrike().run()}
              title="Strike-through"
            >
              <Icon type="Strikethrough" />
            </ActionButton>
          )}
        </ActionsGroup>

        <ActionsGroup>
          {!removeOptions?.includes('paragraph') && (
            <ActionButton
              $isActive={editor?.isActive('paragraph')}
              onClick={() => editor?.chain().focus().setParagraph().run()}
              title="Paragraph"
            >
              P
            </ActionButton>
          )}

          {!removeOptions?.includes('heading1') && (
            <ActionButton
              $isActive={editor?.isActive('heading', { level: 1 })}
              onClick={() => editor?.chain().focus().toggleHeading({ level: 1 }).run()}
              title="Heading 1"
            >
              H1
            </ActionButton>
          )}

          {!removeOptions?.includes('heading2') && (
            <ActionButton
              $isActive={editor?.isActive('heading', { level: 2 })}
              onClick={() => editor?.chain().focus().toggleHeading({ level: 2 }).run()}
              title="Heading 2"
            >
              H2
            </ActionButton>
          )}

          {!removeOptions?.includes('heading3') && (
            <ActionButton
              $isActive={editor?.isActive('heading', { level: 3 })}
              onClick={() => editor?.chain().focus().toggleHeading({ level: 3 }).run()}
              title="Heading 3"
            >
              H3
            </ActionButton>
          )}
        </ActionsGroup>

        <ActionsGroup>
          {!removeOptions?.includes('bulletList') && (
            <ActionButton
              $isActive={editor?.isActive('bulletList')}
              disabled={!editor?.can().chain().focus().toggleBulletList().run()}
              onClick={() => editor?.chain().focus().toggleBulletList().run()}
              title="Bullet List"
            >
              <Icon type="BulletList" />
            </ActionButton>
          )}

          {!removeOptions?.includes('orderedList') && (
            <ActionButton
              $isActive={editor?.isActive('orderedList')}
              onClick={() => editor?.chain().focus().toggleOrderedList().run()}
              title="Ordered List"
            >
              <Icon type="OrderedList" />
            </ActionButton>
          )}

          {!removeOptions?.includes('horizontalRule') && (
            <ActionButton onClick={() => editor?.chain().focus().setHorizontalRule().run()} title="Horizontal Rule">
              <Icon type="Sales" />
            </ActionButton>
          )}

          {!removeOptions?.includes('lineBreak') && (
            <ActionButton onClick={() => editor?.chain().focus().setHardBreak().run()} title="Line Break">
              <Icon type="LineBreak" />
            </ActionButton>
          )}

          {!removeOptions?.includes('blockquote') && (
            <ActionButton
              $isActive={editor?.isActive('blockquote')}
              onClick={() => editor?.chain().focus().toggleBlockquote().run()}
              title="Quote Block"
            >
              <Icon type="QuoteBlock" />
            </ActionButton>
          )}
        </ActionsGroup>

        <ActionsGroup>
          {!removeOptions?.includes('undo') && (
            <ActionButton
              disabled={!editor?.can().chain().focus().undo().run()}
              onClick={() => editor?.chain().focus().undo().run()}
              title="Undo"
            >
              <Icon type="Undo" />
            </ActionButton>
          )}

          {/* {!removeOptions?.includes('clear') && (
            <ActionButton onClick={() => editor?.chain().focus().clearNodes().run()} title="Clear Format">
              <Icon type="Clear" />
            </ActionButton>
          )} */}
        </ActionsGroup>
      </TextEditorActionsContainer>
      <EditContentContainer editor={editor} />
    </TextEditorContainer>
  );
}
