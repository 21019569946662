import styled from 'styled-components';

export const SocialAccountInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[2]}px;
`;

export const NoSocialAccountsMessage = styled.span`
  font-size: ${({ theme }) => theme.core.fontSizes.sm}px;
  font-weight: ${({ theme }) => theme.core.fontWeight.medium};
`;

export const AddSocialAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: grid;
    grid-template-columns: auto 150px;
    grid-template-rows: auto;
  }
`;
