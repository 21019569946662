import styled from 'styled-components';

const DELETE_BUTTON_HEIGHT = 40;

export const FileUploadContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  input {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;

    cursor: pointer;
  }

  label {
    width: 100%;
  }
`;

export const DeleteButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: ${DELETE_BUTTON_HEIGHT}px;
  width: ${DELETE_BUTTON_HEIGHT}px;

  background: ${({ theme }) => theme.core.colors.greyscale.white};
  opacity: 0.6;

  border: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};
  border-radius: ${DELETE_BUTTON_HEIGHT / 2}px;

  svg {
    fill: ${({ theme }) => theme.core.colors.danger.medium};
  }
`;
