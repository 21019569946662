import styled from 'styled-components';

interface ErrorMessageContainer {
  $isFieldError: boolean;
}

export const ErrorMessageContainer = styled.p<ErrorMessageContainer>`
  color: ${({ theme }) => theme.core.colors.danger.medium};

  font-size: ${({ theme, $isFieldError }) => ($isFieldError ? theme.core.fontSizes.sm : theme.core.fontSizes.sm)}px;
`;
