import React from 'react';
import { RestrictedColumnWidthContainer } from './restricted-column-width.styled';

interface RestrictedColumnWidthProps {
  children: React.ReactNode;
}

export function RestrictedColumnWidth({ children }: RestrictedColumnWidthProps) {
  return (
    <RestrictedColumnWidthContainer animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{ duration: 0.3 }}>
      {children}
    </RestrictedColumnWidthContainer>
  );
}
