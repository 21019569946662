import React from 'react';
import { SectionContainerComponent } from './section-container.styled';

interface SectionContainerProps {
  children: React.ReactNode;
}

export function SectionContainer({ children }: SectionContainerProps) {
  return <SectionContainerComponent>{children}</SectionContainerComponent>;
}
