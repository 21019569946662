import React from 'react';
import { FormSection } from '@pata-app/ui-form';
import { useTranslation } from 'react-i18next';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { FormContainer } from '../../../../../../components/layouts/app-layout/components/form-container/form-container';
import { type OpeningTimesSection } from '../../../../../../types/workplace.types';
import { WeekTimesInput } from '../../../../../../components/week-times-input/week-times-input';
import { OpeningTimesContainer } from './opening-times.styled';

interface LocationProps {
  form: UseFormReturn<OpeningTimesSection>;
}

export function OpeningTimes({ form }: LocationProps) {
  const { t } = useTranslation('workplaces');

  const { control } = form;

  return (
    <FormContainer>
      <FormSection description={t('opening_times_description')} title={t('opening_times')}>
        <OpeningTimesContainer>
          <Controller
            control={control}
            name="openingTimes"
            render={({ field }) => {
              return <WeekTimesInput {...field} maxNumberOfPeriods={2} />;
            }}
          />
        </OpeningTimesContainer>
      </FormSection>
    </FormContainer>
  );
}
