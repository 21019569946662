import React from 'react';
import { TimeInput } from '@pata-app/ui-form';
import { type DayTime } from '../../../../week-times-input';
import { Icon } from '../../../../../icon/icon';
import { PeriodActionButton, PeriodContainer } from './period.styled';
import { PeriodTimeUnit } from './period.config';

interface PeriodProps {
  index: number;
  handlePeriodChange: (periodNumber: number, value: DayTime) => void;
  handleAddPeriod: () => void;
  handleRemovePeriod: (dayNumber: number) => void;
  value: DayTime;
  previousPeriodValue?: DayTime;
  nextPeriodValue?: DayTime;
  periodsLength: number;
  allowMultiPeriods: boolean;
}

export function Period({
  handlePeriodChange,
  handleAddPeriod,
  handleRemovePeriod,
  index,
  value,
  previousPeriodValue,
  nextPeriodValue,
  periodsLength,
  allowMultiPeriods,
}: PeriodProps) {
  function handleStartTimeChange(updatedStartTime: React.ChangeEvent<HTMLSelectElement>) {
    const updatedTimePeriods = { ...value, startTime: Number(updatedStartTime.target.value) };

    handlePeriodChange(index, updatedTimePeriods);
  }

  function handleEndTimeChange(updatedEndTime: React.ChangeEvent<HTMLSelectElement>) {
    const updatedTimePeriods = { ...value, endTime: Number(updatedEndTime.target.value) };

    handlePeriodChange(index, updatedTimePeriods);
  }

  function handleActionButtonClick() {
    if (periodsLength > 1 || allowMultiPeriods) {
      handleRemovePeriod(index);
    } else {
      handleAddPeriod();
    }
  }
  return (
    <PeriodContainer>
      <TimeInput
        onChange={handleStartTimeChange}
        timesFrom={previousPeriodValue?.endTime ? previousPeriodValue.endTime + PeriodTimeUnit : 0}
        timesTo={Math.min(value.endTime - PeriodTimeUnit, nextPeriodValue?.startTime ?? 86100)}
        value={value.startTime}
      />
      <span>-</span>
      <TimeInput
        onChange={handleEndTimeChange}
        timesFrom={Math.max(previousPeriodValue?.endTime ?? 0, value.startTime + PeriodTimeUnit)}
        timesTo={nextPeriodValue?.startTime ? nextPeriodValue.startTime - PeriodTimeUnit : 86100}
        value={value.endTime}
      />

      {periodsLength > 1 && (
        <PeriodActionButton disabled={periodsLength <= 1 && allowMultiPeriods} onClick={handleActionButtonClick}>
          <Icon size="14px" type={periodsLength > 1 || allowMultiPeriods ? 'Close' : 'Add'} />
        </PeriodActionButton>
      )}
    </PeriodContainer>
  );
}
