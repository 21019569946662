import React from 'react';
import { NotificationBodyContainer } from './notification-body.styled';

interface NotificationBodyProps {
  title: string;
  description: string;
}

export function NotificationBody({ title, description }: NotificationBodyProps) {
  return (
    <NotificationBodyContainer>
      <h1>{title}</h1>
      <h2>{description}</h2>
    </NotificationBodyContainer>
  );
}
