import React from 'react';
import { Icon } from '../icon/icon';
import { EmptyStateContainer, EmptyStateDescription, EmptyStateTitle } from './empty-state.styled';

interface EmptyStateProps {
  title: string;
  description?: string;
  icon: string;
  onClick?: () => void;
}

export function EmptyState({ title, icon, description, onClick }: EmptyStateProps) {
  return (
    <EmptyStateContainer as={onClick ? 'button' : 'div'} onClick={onClick}>
      <Icon size="50px" type={icon} />
      <EmptyStateTitle>{title}</EmptyStateTitle>
      <EmptyStateDescription>{description}</EmptyStateDescription>
    </EmptyStateContainer>
  );
}
