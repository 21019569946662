import styled from 'styled-components';

export const AutoCompleteContainer = styled.div`
  .getAddress_autocomplete_list {
    border: ${({ theme }) => theme.core.colors.greyscale.medium} 1px solid;
    border-radius: ${({ theme }) => theme.core.border.element.radius}px;

    margin-top: ${({ theme }) => theme.core.sizing[0]}px;

    li {
      min-height: 40px;

      line-height: 40px;
    }
  }
`;
