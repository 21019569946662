import styled from 'styled-components';
import { motion } from 'framer-motion';

export const RestrictedColumnWidthContainer = styled(motion.div)`
  width: 100%;

  margin: auto;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 1100px;
  }
`;
