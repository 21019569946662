import styled from 'styled-components';

export const WorkplaceTilesContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.core.sizing[3]}px;

  margin-bottom: 100px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;
