import { createGlobalStyle } from 'styled-components';
import { coreGlobalStyles, appTheme as designSystemAppTheme } from '@pata-app/themes';
import { createStyledBreakpointsTheme } from 'styled-breakpoints';

export const breakpointTheme = createStyledBreakpointsTheme();

export const appTheme = {
  ...designSystemAppTheme,
  ...breakpointTheme,

  core: {
    ...designSystemAppTheme.core,
    fontSizes: {
      ...designSystemAppTheme.core.fontSizes,
      m: 16,
    },
  },
};

export const GlobalStyles = createGlobalStyle`
${coreGlobalStyles}

html, body, #root {
    height: 100%;

        * {
  font-synthesis: none !important;
}

    >* {

      line-height: 1.2;
    }



    font-family: 'Jost';
    color: ${({ theme }) => theme.core.colors.greyscale.black};
    
    &.modal-open {
      overflow: hidden;
    }
    
  }

  body {
    overflow:hidden;
  }
  
  a {
    color: ${({ theme }) => theme.core.colors.info.medium};
    text-decoration: none;
  }
  
  button {
  color: ${({ theme }) => theme.core.colors.greyscale.black};
  &:active { 
    transform: scale(0.99);
  } 
}

    input, textarea {
      font-size: 16px;
    }
  

`;

export type TAppTheme = typeof appTheme;
