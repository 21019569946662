import React from 'react';
import { Icon } from '../icon/icon';
import { IconPlaceholderContainer } from './icon-placeholder.styled';

interface IconPlaceholderProps {
  icon: string;
  size: string;
}

export function IconPlaceholder({ icon, size = '100px' }: IconPlaceholderProps) {
  return (
    <IconPlaceholderContainer>
      <Icon size={size} type={icon} />
    </IconPlaceholderContainer>
  );
}
