import React, { useEffect, useState } from 'react';
import { FormField, FormLayout, FormSection, Input, Toggle, UnitInput } from '@pata-app/ui-form';
import { autocomplete } from 'getaddress-autocomplete';
import { useTranslation } from 'react-i18next';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { FormContainer } from '../../../../../../components/layouts/app-layout/components/form-container/form-container';
import type { LocationSection } from '../../../../../../types/workplace.types';
import { AutoCompleteContainer } from './location.styled';

interface LocationProps {
  form: UseFormReturn<LocationSection>;
}

export function Location({ form }: LocationProps) {
  const { t } = useTranslation('workplaces');

  const [expandAddress, setExpandAddress] = useState(false);

  const {
    watch,
    control,
    register,
    formState: { errors },
    getValues,
    setValue,
  } = form;

  useEffect(() => {
    autocomplete('address_input', process.env.GET_ADDRESS_API_KEY ?? '', {
      show_all_for_postcode: true,
    });

    document.addEventListener('getaddress-autocomplete-address-selected', (e) => {
      setValue(
        'locationAddress',
        {
          line1: e.address.line_1,
          line2: e.address.line_2,
          line3: e.address.line_3,
          city: e.address.town_or_city,
          region: e.address.county,
          postcode: e.address.postcode,
          country: 'UK',
          latitude: e.address.latitude,
          longitude: e.address.longitude,
        },
        { shouldValidate: true },
      );
    });

    return () => {
      document.removeEventListener('getaddress-autocomplete-address-selected', () => {});
    };
  }, [setValue, getValues]);

  const postcode = watch('locationAddress.postcode');

  useEffect(() => {
    if (postcode) {
      setExpandAddress(true);
    }
  }, [postcode]);

  return (
    <FormContainer>
      <FormSection description={t('location_description')} title={t('location')}>
        <FormLayout columnConfig={{ m: 2 }}>
          <FormField error={errors.locationAddress?.line1?.message} label={t('address_line_1', { ns: 'common' })}>
            <AutoCompleteContainer>
              <Input
                {...register('locationAddress.line1', {
                  required: 'First line of the address is required',
                  maxLength: {
                    value: 150,
                    message: 'Maximum character length is 150',
                  },
                  setValueAs: (value: string) => value.trim(),
                })}
                id="address_input"
                placeholder="Start typing address..."
              />
            </AutoCompleteContainer>
          </FormField>
          <FormField error={errors.hideAddress?.message} label={t('hide_address')}>
            <Controller
              control={control}
              name="hideAddress"
              render={({ field }) => {
                return (
                  <div
                    style={{
                      height: '45px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Toggle {...field} checked={field.value as boolean} label={t('hide_address')} />
                  </div>
                );
              }}
            />
          </FormField>
        </FormLayout>

        {Boolean(expandAddress) && (
          <>
            <FormLayout columnConfig={{ m: 2 }}>
              <FormField error={errors.locationAddress?.line2?.message} label={t('address_line_2', { ns: 'common' })}>
                <Input
                  {...register('locationAddress.line2', {
                    required: false,
                    maxLength: {
                      value: 150,
                      message: 'Maximum character length is 150',
                    },
                    setValueAs: (value: string) => (value ? value.trim() : null),
                  })}
                />
              </FormField>
            </FormLayout>
            <FormLayout columnConfig={{ m: 2 }}>
              <FormField error={errors.locationAddress?.line3?.message} label={t('address_line_3', { ns: 'common' })}>
                <Input
                  {...register('locationAddress.line3', {
                    required: false,
                    maxLength: {
                      value: 150,
                      message: 'Maximum character length is 150',
                    },
                    setValueAs: (value: string) => (value ? value.trim() : null),
                  })}
                />
              </FormField>
            </FormLayout>
            <FormLayout columnConfig={{ m: 2 }}>
              <FormField error={errors.locationAddress?.city?.message} label={t('city', { ns: 'common' })}>
                <Input
                  {...register('locationAddress.city', {
                    required: 'City is required',
                    maxLength: {
                      value: 50,
                      message: 'Maximum character length is 50',
                    },
                    setValueAs: (value: string) => (value ? value.trim() : null),
                  })}
                />
              </FormField>
              <FormField error={errors.locationAddress?.region?.message} label={t('region', { ns: 'common' })}>
                <Input
                  {...register('locationAddress.region', {
                    required: false,
                    maxLength: {
                      value: 50,
                      message: 'Maximum character length is 50',
                    },
                    setValueAs: (value: string) => (value ? value.trim() : null),
                  })}
                />
              </FormField>
            </FormLayout>
            <FormLayout columnConfig={{ m: 2 }}>
              <FormField error={errors.locationAddress?.country?.message} label={t('country', { ns: 'common' })}>
                <Input
                  {...register('locationAddress.country', {
                    required: 'Country is required',
                    maxLength: {
                      value: 50,
                      message: 'Maximum character length is 50',
                    },
                    setValueAs: (value: string) => (value ? value.trim() : null),
                  })}
                />
              </FormField>
              <FormField error={errors.locationAddress?.postcode?.message} label={t('postcode', { ns: 'common' })}>
                <Input
                  {...register('locationAddress.postcode', {
                    required: 'Postcode is required',
                    maxLength: {
                      value: 50,
                      message: 'Maximum character length is 50',
                    },
                    setValueAs: (value: string) => value.trim(),
                  })}
                  style={{ textTransform: 'uppercase' }}
                />
              </FormField>
            </FormLayout>
          </>
        )}
      </FormSection>

      <FormSection title={t('travel_settings')}>
        <FormLayout columnConfig={{ m: 2 }}>
          <FormField
            description="If your workplace offers services remotely specify a travel distance radius from your location."
            error={errors.travelDistance?.message}
            label={t('travel_distance')}
          >
            <Controller
              control={control}
              name="travelDistance"
              render={({ field }) => {
                return (
                  <UnitInput
                    {...field}
                    hasError={Boolean(errors.travelDistance)}
                    onChange={(updatedValue) => {
                      field.onChange(parseInt(updatedValue.target.value));
                    }}
                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                      event.target.value = event.target.value.replace(/[^0-9]/g, '');
                    }}
                    placeholder="Enter your Travel Distance"
                    suffix={t('miles')}
                    type="number"
                    value={field.value ? field.value : null}
                  />
                );
              }}
              rules={{
                validate: (value: number) => {
                  return value && value > 100 ? 'Maximum Travel Distance is 100 miles' : undefined;
                },
              }}
            />
          </FormField>
        </FormLayout>
      </FormSection>
    </FormContainer>
  );
}
