import React from 'react';
import { Image } from '../../../../../../../components/image/image';
import { Icon } from '../../../../../../../components/icon/icon';
import { MediaTileContainer } from './media-tile.styled';

interface MediaTileProps {
  path: string;
  handleDelete: (path: string) => void;
}

export function MediaTile({ path, handleDelete }: MediaTileProps) {
  function onDelete() {
    handleDelete(path);
  }
  return (
    <MediaTileContainer>
      <Image src={`${process.env.CDN_BASE_URL_USER_CONTENT}/${path}`} />
      <button onClick={onDelete} type="button">
        <Icon type="Delete" />
      </button>
    </MediaTileContainer>
  );
}
