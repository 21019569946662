import styled from 'styled-components';

export const EmptyStateContainer = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 300px;
  width: 100%;

  border-radius: ${({ theme }) => theme.core.border.container.radius}px;

  border: ${({ theme }) => theme.core.colors.greyscale.medium} 0.5px solid;

  background: none;

  padding: ${({ theme }) => theme.core.sizing[1]}px;

  svg {
    fill: ${({ theme }) => theme.core.colors.primary.green.medium};
  }
`;

export const EmptyStateTitle = styled.h2`
  margin-top: ${({ theme }) => theme.core.sizing[2]}px;

  font-size: ${({ theme }) => theme.core.fontSizes.l}px;
  font-weight: ${({ theme }) => theme.core.fontWeight.medium};
`;

export const EmptyStateDescription = styled.p`
  margin-top: ${({ theme }) => theme.core.sizing[0]}px;

  font-size: ${({ theme }) => theme.core.fontSizes.sm}px;

  color: ${({ theme }) => theme.core.colors.greyscale.dark};
`;
