import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { AppLayout } from './components/layouts/app-layout/app-layout';
import { GlobalStyles, appTheme } from './styles/themes';
import { Menu } from './pages/menu/menu';
import { AuthLayout } from './components/layouts/auth-layout/auth-layout';
import { ResetPassword } from './pages/reset-password/reset-password';
import { ForgottenPassword } from './pages/forgotten-password/forgotten-password';
import { Logout } from './pages/logout/logout';
import { queryClient } from './queries/query-client';
import { useAmplifySetup } from './hooks/use-amplify-setup';
import { AuthProvider } from './contexts/auth.context';
import { ToastNotifications } from './components/toast-notifications/toast-notifications';
import { PostAuth } from './pages/post-auth/post-auth';
import { AuthRoute } from './components/layouts/app-layout/components/auth-route/auth-route';
import { OnboardingRoute } from './components/layouts/app-layout/components/onboarding-route/onboarding-route';
import { ErrorBoundary } from './components/error-boundary/error-boundary';
import { OnlineBooking } from './pages/online-booking/online-booking';
import './styles/fonts.css';
import Calendar from './pages/calendar/calendar';
import Signup from './pages/sign-up/signup';

// const Calendar = lazy(() => import(/* webpackChunkName: "calendar" */ './pages/calendar/calendar'));
const Catalogue = lazy(() => import(/* webpackChunkName: "catalogue" */ './pages/catalogue/catalogue'));
const Clients = lazy(() => import(/* webpackChunkName: "clients" */ './pages/clients/clients'));
const Sales = lazy(() => import(/* webpackChunkName: "sales" */ './pages/sales/sales'));
const Team = lazy(() => import(/* webpackChunkName: "team" */ './pages/team/team'));
const User = lazy(() => import(/* webpackChunkName: "user" */ './pages/user/user'));
// const Signup = lazy(() => import(/* webpackChunkName: "signup" */ './pages/sign-up/signup'));
const Payments = lazy(() => import(/* webpackChunkName: "payments" */ './pages/payments/payments'));
const Settings = lazy(() => import(/* webpackChunkName: "settings" */ './pages/settings/settings'));
const Onboarding = lazy(() => import(/* webpackChunkName: "settings" */ './pages/onboarding/onboarding'));

export function App() {
  const { isAmplifyConfigured } = useAmplifySetup();

  if (isAmplifyConfigured)
    return (
      <ThemeProvider theme={appTheme}>
        <ErrorBoundary>
          <GlobalStyles />
          <BrowserRouter>
            <AuthProvider>
              <QueryClientProvider client={queryClient}>
                <Suspense fallback={<></>}>
                  <Routes>
                    <Route element={<AppLayout />} path="/">
                      <Route element={<Navigate to="/calendar" />} index />
                      <Route element={<Calendar />} path="/calendar/*" />
                      <Route element={<Catalogue />} path="/catalogue/*" />
                      <Route element={<Clients />} path="/clients/*" />
                      <Route element={<Sales />} path="/sales/*" />
                      <Route element={<OnlineBooking />} path="/online-booking/*" />
                      <Route element={<Team />} path="/team/*" />
                      <Route element={<Settings />} path="/settings/*" />
                      <Route element={<Menu />} path="/menu/*" />
                      <Route element={<User />} path="/user/*" />
                      <Route element={<Payments />} path="/payments/*" />
                    </Route>
                    <Route element={<AuthLayout />} path="/">
                      <Route element={<Signup />} path="sign-up" />
                      <Route element={<Signup />} path="login" />
                      <Route element={<ResetPassword />} path="reset-password" />
                      <Route element={<ForgottenPassword />} path="forgotten-password" />
                    </Route>
                    <Route element={<AuthLayout postAuth />} path="/post-auth">
                      <Route element={<PostAuth />} path="details" />
                    </Route>
                    <Route element={<AuthRoute />} path="/auth/*" />
                    <Route
                      element={
                        <OnboardingRoute>
                          <Onboarding />
                        </OnboardingRoute>
                      }
                      path="/onboarding/*"
                    />
                    <Route element={<Logout />} path="logout" />
                  </Routes>
                </Suspense>
              </QueryClientProvider>
              <ToastNotifications />
            </AuthProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    );

  return null;
}
