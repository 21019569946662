import styled from 'styled-components';

export const OpeningTimesContainer = styled.div`
  display: flex;
  justify-content: center;

  > div {
    max-width: 600px;
  }
`;
