import React from 'react';
import { Input } from '@pata-app/ui-form';
import { SOCIAL_ACCOUNTS } from '../social-account-input.config';
import { Icon } from '../../../../../../../../../components/icon/icon';
import { DeleteButton, SocialAccountContainer, SocialAccountName } from './social-account.styled';

interface SocialAccountProps {
  socialName: string;
  socialUrl: string;
  onChange: (socialName: string, value: string) => void;
  onDelete: (socialName: string) => void;
  onBlur: (event: any) => void;
}

export function SocialAccount({ socialName, socialUrl, onBlur, onChange, onDelete }: SocialAccountProps) {
  function handleDelete() {
    onDelete(socialName);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(socialName, event.target.value);
  }

  return (
    <SocialAccountContainer>
      <DeleteButton onClick={handleDelete}>
        <Icon type="Close" />
      </DeleteButton>
      <SocialAccountName>{SOCIAL_ACCOUNTS.find((account) => account.id === socialName)?.label}</SocialAccountName>
      <Input onBlur={onBlur} onChange={handleChange} placeholder="https://" type="url" value={socialUrl} />
    </SocialAccountContainer>
  );
}
