export const SOCIAL_ACCOUNTS = [
  {
    id: 'facebook',
    label: 'Facebook',
  },
  {
    id: 'instagram',
    label: 'Instagram',
  },
  {
    id: 'threads',
    label: 'Threads',
  },
  {
    id: 'twitter',
    label: 'Twitter',
  },
  {
    id: 'tiktok',
    label: 'TikTok',
  },
  {
    id: 'youtube',
    label: 'YouTube',
  },
  {
    id: 'pinterest',
    label: 'Pinterest',
  },
  {
    id: 'linkedin',
    label: 'LinkedIn',
  },
  {
    id: 'snapchat',
    label: 'Snapchat',
  },
];
