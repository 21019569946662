import React from 'react';
import { ErrorMessage } from '../error-message/error-message';
import { LabelElement } from './field-label.styled';

interface FieldLabelProps {
  label: string;
}

export function FieldLabel({ label, error }: FieldLabelProps) {
  return (
    <>
      <ErrorMessage>{error}</ErrorMessage>
      <LabelElement>{label}</LabelElement>
    </>
  );
}
