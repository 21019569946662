import styled from 'styled-components';

interface ActionContainerProps {
  actionType: string;
}

export const ActionContainer = styled.button<ActionContainerProps>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.core.sizing[1]}px;

  height: 40px;
  width: 100%;

  padding: 0 ${({ theme }) => theme.core.sizing[2]}px;

  border: none;
  border-radius: ${({ theme }) => theme.core.border.element.radius / 2}px;

  background: none;

  text-align: left;
  font-size: ${({ theme }) => theme.core.fontSizes.sm}px;
  color: ${({ actionType, theme }) =>
    actionType === 'danger' ? theme.core.colors.danger.medium : theme.core.colors.greyscale.black};

  transition: 0.2s all ease-in-out;

  &:hover {
    background: ${({ theme }) => `${theme.core.colors.primary.green.medium}33`};
  }

  svg {
    fill: ${({ actionType, theme }) =>
      actionType === 'danger' ? theme.core.colors.danger.medium : theme.core.colors.greyscale.black};
  }
`;
