import React from 'react';
import { Navigate } from 'react-router-dom';
import { useGetUser } from '../../../../../queries/user.queries';
import { useAuthContext } from '../../../../../contexts/auth.context';

export function AuthRoute() {
  const { isAuthenticated } = useAuthContext();

  const { data: userData, isLoading } = useGetUser({ enabled: Boolean(isAuthenticated) });

  if (isLoading || isAuthenticated === null) return null;

  if (isAuthenticated && !userData?.accounts?.length) {
    return <Navigate to="/onboarding" />;
  }

  if (isAuthenticated && userData?.accounts?.length) return <Navigate to="/calendar" />;

  if (isAuthenticated && !userData?.phoneNumber) {
    return <Navigate to="/post-auth/details" />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <Navigate to="/" />;
}
