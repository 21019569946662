import { useLocation, useNavigate } from 'react-router-dom';

export function useNavigateUsingReferrer() {
  const location = useLocation();
  const navigate = useNavigate();

  function navigateUsingReferrer(path?: string) {
    const updatedRoute = location.state?.referrer ?? path;

    navigate(updatedRoute, { replace: true });
  }

  return navigateUsingReferrer;
}
