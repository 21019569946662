import styled from 'styled-components';

interface DayContainerProps {
  isDisabled: boolean;
}

export const DayContainer = styled.div<DayContainerProps>`
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-rows: auto auto;
  align-items: flex-start;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  padding: ${({ theme }) => theme.core.sizing[3]}px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};
  }

  > span {
    text-transform: capitalize;
    font-size: ${({ theme }) => theme.core.fontSizes.ml}px;

    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.core.colors.greyscale.medium : theme.core.colors.greyscale.black};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: 100px auto 350px;
    row-gap: 0;
    column-gap: ${({ theme }) => theme.core.sizing[2]}px;
  }
`;

export const PeriodsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[1]}px;
  grid-row: 2 / span 1;
  grid-column: 1 / span 2;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-row: 1 / span 1;
    grid-column: 3 / span 1;
  }
`;

export const ClosedContainer = styled.span`
  display: flex;
  align-items: center;

  height: 45px;
`;

export const AddPeriodButton = styled.button`
  align-self: flex-end;

  background: none;

  border: none;

  color: ${({ theme }) => theme.core.colors.primary.green.medium};
`;
