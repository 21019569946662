import React, { type RefObject, useRef, useState, cloneElement } from 'react';
import { Button } from '@pata-app/ui-form';
import { useClickOutside } from '@pata-app/hooks';
import { Icon } from '../icon/icon';
import { ActionMenuContainer, ActionsContainer, ButtonContentContainer } from './actions-menu.styled';
import { ActionItem } from './components/action-item/action-item';

export interface Action {
  label: string;
  id: string;
  icon?: string;
  href?: string;
  onClick?: (id: string) => void;
  actionType?: string;
  hide?: boolean;
  disable?: boolean;
}

export interface ActionsMenuProps {
  alignment?: 'right' | 'left';
  position?: 'top' | 'bottom';
  menuOptions: Action[];
  buttonText?: string;
  buttonType?: 'primary' | 'secondary' | 'danger' | 'cta';
  children?: React.ReactNode;
  icon?: string;
  menuOffset?: number;
}

export function ActionsMenu({
  alignment = 'left',
  position = 'top',
  children,
  menuOptions,
  buttonText,
  buttonType = 'primary',
  icon,
  menuOffset = 50,
}: ActionsMenuProps) {
  const [showMenu, setShowMenu] = useState(false);

  const containerRef = useRef<RefObject<HTMLDivElement> | null>(null);

  useClickOutside(containerRef as RefObject<HTMLDivElement>, () => {
    setShowMenu(false);
  });

  function handleToggleMenu(event: React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    setShowMenu((currentToggle) => !currentToggle);
  }

  return (
    <ActionMenuContainer ref={containerRef}>
      {Boolean(children) && cloneElement(children as React.ReactElement, { onClick: handleToggleMenu, showMenu })}
      {!children && (
        <Button buttonType={buttonType} onClick={handleToggleMenu}>
          <ButtonContentContainer>
            {Boolean(buttonText) && buttonText}

            <Icon size="16px" type={icon ? icon : 'Arrow'} />
          </ButtonContentContainer>
        </Button>
      )}
      {Boolean(showMenu) && (
        <ActionsContainer
          $alignment={alignment}
          $offset={menuOffset}
          $position={position}
          // animate={{ opacity: 1 }}
          // initial={{ opacity: 0 }}
          // transition={{ duration: 0.2 }}
        >
          {menuOptions.map((action) => (
            <ActionItem action={action} key={action.id} setShowMenu={setShowMenu} />
          ))}
        </ActionsContainer>
      )}
    </ActionMenuContainer>
  );
}
