import React from 'react';
import { Toggle } from '@pata-app/ui-form';
import { type DayTime, type DayValue } from '../../week-times-input';
import { AddPeriodButton, ClosedContainer, DayContainer, PeriodsContainer } from './week-time-day.styled';
import { Period } from './components/period/period';

export interface ChangeDayValue {
  day: number;
  periods: null | DayTime[];
}

interface TimeDayProps {
  day: string;
  index: number;
  value: DayValue | undefined;
  onChange: (updatedValue: ChangeDayValue) => void;
  noPeriodsLabel: string;
  allowMultiPeriods: boolean;
  maxNumberOfPeriods?: number;
  keyword: string;
}

export function TimeDay({
  day,
  index,
  value,
  onChange,
  noPeriodsLabel,
  allowMultiPeriods,
  keyword,
  maxNumberOfPeriods,
}: TimeDayProps) {
  function handleHandleToggleChange(toggle: boolean) {
    if (toggle) {
      const updatedDayValue = {
        day: index,
        periods: [
          {
            startTime: 32400,
            endTime: 61200,
          },
        ],
      };

      onChange(updatedDayValue);
    } else {
      const updatedDayValue = {
        day: index,
        periods: null,
      };

      onChange(updatedDayValue);
    }
  }

  function handlePeriodChange(periodNumber: number, updatedPeriod: DayTime) {
    const updatedPeriods = value?.periods.map((period, periodIndex) =>
      periodIndex === periodNumber ? updatedPeriod : period,
    );

    const updatedDayValue = {
      day: value?.day,
      periods: updatedPeriods,
    };

    onChange(updatedDayValue);
  }

  function handleAddPeriod() {
    if (value) {
      const lastTimePeriod = value.periods[value.periods.length - 1];

      const updatedDayValue = {
        ...value,
        periods: [
          ...value.periods,
          {
            startTime: lastTimePeriod.endTime + 3600,
            endTime: lastTimePeriod.endTime + 3600 * 2,
          },
        ],
      };

      onChange(updatedDayValue);
    }
  }

  function handleRemovePeriod(deleteIndex: number) {
    const updatedDayValue = {
      ...value,
      periods: value?.periods.filter((_, periodIndex) => periodIndex !== deleteIndex),
    };

    onChange(updatedDayValue);
  }

  const lastTimePeriodEndTime = value?.periods[value.periods.length - 1].endTime;

  const addPeriodButton = 86400 - 7200 > (lastTimePeriodEndTime ?? 0);

  return (
    <DayContainer isDisabled={!value}>
      <div>
        <Toggle checked={Boolean(value)} onChange={handleHandleToggleChange} />
      </div>
      <span>{day}</span>
      {value ? (
        <PeriodsContainer>
          {value.periods.map((period, periodIndex) => (
            <Period
              allowMultiPeriods={allowMultiPeriods}
              handleAddPeriod={handleAddPeriod}
              handlePeriodChange={handlePeriodChange}
              handleRemovePeriod={handleRemovePeriod}
              index={periodIndex}
              key={period.startTime}
              nextPeriodValue={value.periods[periodIndex + 1]}
              periodsLength={value.periods.length}
              previousPeriodValue={value.periods[periodIndex - 1]}
              value={period}
            />
          ))}
          {Boolean(addPeriodButton) && (!maxNumberOfPeriods || maxNumberOfPeriods > value.periods.length) && (
            <AddPeriodButton onClick={handleAddPeriod}>{`Add ${keyword}`}</AddPeriodButton>
          )}
        </PeriodsContainer>
      ) : (
        <ClosedContainer>{noPeriodsLabel}</ClosedContainer>
      )}
    </DayContainer>
  );
}
