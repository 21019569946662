import { type Media } from '../../../../../types/workplace.types';

export function formatMedia(updatedMedia: Media[], exisitingMedia: Media[]) {
  const addedMedia = updatedMedia.reduce((addedItems, mediaItem) => {
    if (!mediaItem.id) {
      return [
        ...addedItems,
        {
          ...mediaItem,
          action: 'add',
        },
      ];
    }

    return addedItems;
  }, []);

  const updatedMediaIds = updatedMedia.map((media) => media.id);

  const removedMedia = exisitingMedia.reduce((removedItems, mediaItem) => {
    if (!updatedMediaIds.includes(mediaItem.id)) {
      return [
        ...removedItems,
        {
          id: mediaItem.id,
          action: 'remove',
        },
      ];
    }

    return removedItems;
  }, []);

  return [...addedMedia, ...removedMedia];
}
